import * as React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function ProductListBlock({
  subheading,
  heading,
  text,
  buttons,
  features,
  columns = 5,
  ...props
}) {


  return (
    <section py="2|3|6|10" {...props}>
      <div variant="container">
        <div textAlign="center">
          {subheading && <p variant="subheading">{subheading}</p>}
          {heading && (
            <h3 variant="heading.h1" lineHeight="1">
              {heading}
            </h3>
          )}
          {text && (
            <p variant="text.lead" mt="2">
              {text}
            </p>
          )}
        </div>
        {features && (
          <div display="grid" col={`1|2|${columns}`} gap="2|4" my="8|12">
            {features.map((feature, index) => (
              <Feature key={index} {...feature} />
            ))}
          </div>
        )}
        {buttons}
      </div>
    </section>
  )
}

export function Feature({
  heading,
  text,
  icon = { name: "check" },
  image,
  link,
  ...props
}) {
  return (
    <Link to={"/solutions/" + link}>
      <div
        display="flex"
        flexDirection="column"
        textAlign="center"
        borderWidth="1"
        rounded="lg"
        sizes="full"
        p="3"
        {...props}
        style={{ minHeight: 320 }}
      >
        {image && <Img fluid={image.fluid} />}

        <div flex="1">
          <h4 variant="heading.h4">{heading}</h4>
          {text && (
            <p variant="text.paragraph text.sm" mt="1">
              {text}
            </p>
          )}
        </div>
      </div>
    </Link>
  )
}
