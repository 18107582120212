import * as React from "react"
import { Icon } from "reflexjs"
import ProductListBlock from "./ProductListBlock"
import { graphql, useStaticQuery } from "gatsby"

export default function ProductListBlockList() {
  const data = useStaticQuery(graphql`
    query ProductListInfo {
      allContentfulProductInfo(sort: { fields: orderno }) {
        nodes {
          slug
          productName
          description {
            description
          }
          productLogo {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)

  const pListInfo = data.allContentfulProductInfo.nodes

  const newFeatures = []

  const createFature = pListInfo.map(feature =>
    newFeatures.push({
      heading: feature.productName,
      text: "",
      icon: {
        name: "activity",
      },
      link: feature.slug,
      image: feature.productLogo || "",
    })
  )

  return (
    <ProductListBlock
      heading="Sipariş Makinesi Nedir?"
      text="Sipariş Makinesi, paket servis yazılımı ve aşağıda bulabileceğiniz ek yazılımlardan ve donanımsal ürünlerden oluşan bir sipariş sistemidir."
      features={newFeatures}
    />
  )
}

/* buttons={
    <div display="flex" alignItems="center" justifyContent="center" gap="4">
      <a variant="button.primary" href="#">
        Get started
        <Icon name="arrow-right" ml="2" size="4" />
      </a>
      <a variant="button.secondary" href="#">
        Learn more
      </a>
    </div>
  } */
