import * as React from "react"
import schemapic from "images/schema.png"
import { Zoom } from "react-reveal"
import config from "react-reveal/globals"
import { Link } from "gatsby"
config({ ssrFadeout: true })


function Block({ heading, text, buttons, ...props }) {
  return (
    <section py="3|4|6|10" {...props}>
      <div variant="container">
        <div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Zoom ssrFadeout>
            <img src={schemapic} alt="Kaldi" style={{ width: "800px" }} />
          </Zoom>

          {heading && (
            <h1 variant="heading.h1" fontWeight="bolder" lineHeight="tight">
              {heading}
            </h1>
          )}
          {text && (
            <p variant="text.lead" mt="2">
              {text}
            </p>
          )}
          {buttons}
        </div>
      </div>
    </section>
  )
}

export default function HomePageCallNow() {
  return (
    <Block
      heading="Tüm Siparişleriniz Tek Noktada"
      text="İster telefonla gelsin siparişler ister internetten.. Artık tüm siparişlerinizi tek bir noktadan yönetebileceksiniz."
      buttons={
        <button variant="button.secondary">
          <a variant="button.success" href="tel:+902125500150" mt="6">
            Hemen İletişime Geç
          </a>
        </button>
      }
    />
  )
}
