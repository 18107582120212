import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"
import Layout from "components/layout"
import SEO from "components/seo"
import HeroBlk from "components/HeroBlk"
import ProductListBlockList from "components/ProductListBlockList"
import HomePageCallNow from "components/HomePageCallNow"
import ContactForm from "components/ContactForm"


const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Sipariş Makinesi Paket Servis Sistemi" keywords={[`sipariş makinesi`, `adelsoft`, `paket servis sistemi`,`müşteri tanıma sistemi`, `yemek sepeti otomatik çıkartma`,`getir otomatik çıkartma`,`sipariş otomatik onaylama`]} />
    <Container className="text-center">
      <Row>
        <Col>
          <HeroBlk />
        </Col>
      </Row>
      <Row className="justify-content-center my-3">
        <ProductListBlockList />
      </Row>
      <Row className="justify-content-center my-3">
        <HomePageCallNow />
      </Row>
      <Row className="justify-content-center my-3">
        {/* <PriceList /> */}
      </Row>
      <Row className="justify-content-center my-3">
       {/*  <CustomerList /> */}
      </Row>
      <Row className="justify-content-center my-3">
        <ContactForm />
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
