import * as React from "react"
import {Fade, Zoom} from "react-reveal"
import config from 'react-reveal/globals';
config({ ssrFadeout: true });

export default function Block({
  subheading,
  heading,
  text,
  image,
  buttons,
  children,
  ...props
}) {
  return (
    <section pt="1|1|1|1" {...props}>
      <div variant="container">
        <div
          display="flex"
          flexDirection="column"
          textAlign="center"
          justifyContent="center"
        >
         <Zoom ssrFadeout>
            {heading && (
              <h1 variant="heading.h1" fontWeight="bolder" lineHeight="tight">
                {heading}
              </h1>
            )}
            </Zoom>
          {text && (
            <p variant="text.lead" mt="2">
              {text}
            </p>
          )}
          <Fade top>
          {buttons}
          </Fade>
          {children}
        </div>
        <div mt="6">
          {" "}
          <iframe
            display="block"
            src="https://www.youtube.com/embed/wV2uQX3nWWw?autoplay=1&loop=1&mute=1&controls=1&vq=hd1080"
            w="full"
            h="40|56|600"
            roundedTop="xl"
            overflow="hidden"
            paddingTop="10px"
          ></iframe>
        </div>
      </div>
    </section>
  )
}
