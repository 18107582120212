import React from "react"
import { Icon } from "reflexjs"
import HeroBlock from "components/Hero"

export default function HeroBlk() {
  return (
    <div>
      <HeroBlock
        heading="Restaurant Paket & Müşteri Takip  Masa Sipariş Sistemi"
        text="Paket Servis Sorunlarınızı %100'e kadar çözüyoruz."
        image={{
          src: "/images/placeholder.jpg",
          alt: "Hero image",
        }}
        buttons={
          <div mt="6">
            <div display="flex" justifyContent="center" gap="4" mt="6">
              <a variant="button.primary.lg" href="/contact">
                Görüşme Talebinde Bulun {""}
                <Icon name="arrow-right" ml="2" size="4" />
              </a>
              <a variant="button.secondary.lg" href="/solutions/standart-paket-sistemi/">
                Daha Fazla Bilgi
              </a>
            </div>
          </div>
        }
      />
    </div>
  )
}
